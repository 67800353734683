import { ReactNode } from 'react';
import { SystemProps, Box } from '@storyofams/react-ui';
import SbEditable from 'storyblok-react';

import { Container, Text, Heading } from '~components';

interface Props extends SystemProps {
  children: ReactNode;
  title?: string;
  description?: string;
  content?: any;
}

export const Layout = ({
  title,
  description,
  children,
  content,
  ...props
}: Props) => (
  <Container
    maxWidth={{ md: '512px' }}
    px={{ all: 2, md: 8, lg: 2 }}
    pt={[5, 10]}
    textAlign="center"
    {...props}
  >
    <SbEditable content={content}>
      {title && (
        <Heading as="h1" variant="h1" mb={description ? 1 : [4, 5]}>
          {title}
        </Heading>
      )}

      {description && (
        <Text mb={[4, 5]} color="grey500" px={{ all: 5, md: 0 }}>
          {description}
        </Text>
      )}
    </SbEditable>

    <Box maxWidth={{ sm: '480px' }} m="0 auto">
      {children}
    </Box>
  </Container>
);
