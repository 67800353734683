import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@storyofams/react-ui';
import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';
import { NextPage, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { Button, Input, Heading, Divider, Seo } from '~components';
import { useShopify } from '~context';
import { enhancedStaticProps, withLayout, getTranslationForLocale } from '~lib';
import { Layout } from '~components/login';
import { CustomerAccessTokenCreateInput } from '~lib/shopify/sdk';
import { AuthpageItem } from '~lib/storyblok/sdk';

const messages = defineMessages({
  title: 'Login',
  seoDescription: 'Login page',
  description: 'Fill out your details in order to sign into your account.',
  email: 'Email address',
  emailPlaceholder: 'Your email',
  password: 'Password',
  passwordPlaceholder: 'Your password',
  forgotPassword: 'Forgot your password?',
  submit: 'Login',
  dontHaveAccount: "Don't have an account?",
  createAccount: 'Create an account',
});

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const LoginPage: NextPage<WithStoryProps> = ({ story }) => {
  const intl = useIntl();
  const router = useRouter();
  const { login, customer } = useShopify();

  const { title, description, seo } =
    (story?.content as AuthpageItem['content']) || {};

  useEffect(() => {
    if (customer?.id) {
      if (
        router.query?.checkout_url &&
        typeof router.query.checkout_url === 'string'
      ) {
        window.location.href = router.query.checkout_url;
      } else {
        if (router.query?.to && router.query?.as) {
          router.push(router.query.to as string, router.query.as as string);
        } else {
          router.push(
            process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' ? '/shop' : '/',
          );
        }
      }
    }
  }, [customer]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<CustomerAccessTokenCreateInput>({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Seo
        {...(seo || {
          title: title || intl.formatMessage(messages.title),
          description: intl.formatMessage(messages.seoDescription),
        })}
      />

      <Layout
        title={title || intl.formatMessage(messages.title)}
        description={description || intl.formatMessage(messages.description)}
        content={story?.content}
      >
        <Box
          as="form"
          onSubmit={handleSubmit(
            (input) =>
              new Promise<void>(async (resolve) => {
                const success = await login(input);

                if (!success) {
                  resolve();
                }
              }),
          )}
          width="100%"
        >
          <Input
            required
            type="email"
            label={intl.formatMessage(messages.email)}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            {...register('email')}
            error={errors?.email?.message}
            mb={4}
          />

          <Input
            required
            type="password"
            label={intl.formatMessage(messages.password)}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            {...register('password')}
            error={errors?.password?.message}
            mb={[2, 1.5]}
          />

          <Button
            to="/forgot-password"
            variant="unstyled"
            mb={[3, 4]}
            display="block"
            width="100%"
            fontSize={1.75}
            color="grey900"
            textAlign="right"
          >
            <FormattedMessage {...messages.forgotPassword} />
          </Button>

          <Button
            isLoading={isSubmitting}
            variant="primary"
            width="100%"
            fontSize={2}
            fontWeight="bold"
            color="white"
            type="submit"
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </Box>
        {process.env.NEXT_PUBLIC_STORE_COUNTRY !== 'b2b' && (
          <>
            <Divider my={5} />
            <Heading as="h2" variant={'h2'} mb={[3, 2]} fontSize={3}>
              <FormattedMessage {...messages.dontHaveAccount} />
            </Heading>

            <Button variant="outline" to="/register" width="100%">
              <FormattedMessage {...messages.createAccount} />
            </Button>
          </>
        )}
      </Layout>
    </>
  );
};

export default withLayout(withStory(LoginPage));

export const getStaticProps: GetStaticProps<WithStoryProps> =
  enhancedStaticProps(async ({ defaultLocale, locale, sdk }) => {
    let story = null;
    const { lang, needsTranslation } = getTranslationForLocale(
      locale,
      defaultLocale,
    );

    try {
      story = (
        await sdk.authPage({
          slug: `${needsTranslation ? `${lang}/` : ''}auth/login`,
          lang: needsTranslation ? lang : 'default',
        })
      ).AuthpageItem;
    } catch (e) {}

    return {
      props: {
        story,
      },
      revalidate: 60,
    };
  });
